import i18n from '@/i18n'
import currency from '@/utils/currency'
import { Prefixed_US_CURRENCY } from '@/utils/constants/currency'

const costLabel = 'productCostLabel'
const incentiveLabel = 'productIncentiveLabel'

const getIncentiveMessage = (country, product, savedReward) => {
  const baseReward = savedReward ? savedReward.reward : product.reward

  if (product.incentive) {
    const basePriceText = currency.exchange(country, baseReward)
    const incentivePriceText = currency.exchange(country, product.incentive)

    return ` (Product Price: ${basePriceText} + Incentive: ${incentivePriceText})`
  }

  return ''
}

const getTotalReward = (product, savedReward) => {
  const baseReward = savedReward ? savedReward.reward : product.reward

  if (product.incentive) {
    return baseReward + product.incentive
  }

  return baseReward
}

export const calculateRewardText = (
  country,
  customStatus,
  reduceRewardInTasting,
  productRewardsRule,
  product,
  savedRewards,
  preRecruitSurvey,
  dualCurrency
) => {
  if (preRecruitSurvey && customStatus && customStatus.enable) {
    return customStatus.inChooseProduct
  }

  const lokalise =
    reduceRewardInTasting || productRewardsRule.active ? 'upToReward' : 'reward'

  const savedReward = savedRewards.find(savedReward => {
    return window.mongoId(savedReward) === window.mongoId(product)
  })

  const incentiveMessage = getIncentiveMessage(country, product, savedReward)
  const totalReward = getTotalReward(product, savedReward)
  const reward = currency.exchange(country, totalReward)

  if (customStatus && customStatus.enable) {
    if (dualCurrency) {
      return `${customStatus.inChooseProduct} ${reward} / ${Prefixed_US_CURRENCY}${savedReward.amountInDollar}${incentiveMessage}`
    }

    return `${customStatus.inChooseProduct} ${reward}${incentiveMessage}`
  }

  if (dualCurrency) {
    const { amountInDollar } = savedReward
    const text = i18n.t(lokalise, { reward })

    return `${text} / ${Prefixed_US_CURRENCY}${amountInDollar}${incentiveMessage}`
  }

  return `${i18n.t(lokalise, { reward })}${incentiveMessage}`
}

const validateRewardVal = val => {
  if (typeof val !== 'number' && val < 0) return ''
  return val.toString()
}

export const incentiveCostValues = (product, country) => {
  if (!product || !product.productIncentiveCost) {
    return {
      cost: 0,
      incentive: 0,
      dollarCost: 0,
      dollarIncentive: 0,
      transformCost: 0,
      transformIncentive: 0
    }
  }
  const cost = validateRewardVal(product.productIncentiveCost.cost)
  const incentive = validateRewardVal(product.productIncentiveCost.incentive)
  const dollarCost = validateRewardVal(product.productIncentiveCost.dollarCost)
  const dollarIncentive = validateRewardVal(
    product.productIncentiveCost.dollarIncentive
  )
  const transformCost = currency.exchange(country, cost)
  const transformIncentive = currency.exchange(country, incentive)

  return {
    cost,
    incentive,
    dollarCost,
    dollarIncentive,
    transformCost,
    transformIncentive
  }
}

export const calculateRewardToolTipText = (
  country,
  customStatus,
  reduceRewardInTasting,
  productRewardsRule,
  product,
  savedRewards,
  preRecruitSurvey,
  dualCurrency
) => {
  if (preRecruitSurvey && customStatus && customStatus.enable) {
    return customStatus.inChooseProduct
  }
  const lokalise =
    reduceRewardInTasting || productRewardsRule.active ? 'upToReward' : 'reward'
  const savedReward = savedRewards.find(savedReward => {
    return window.mongoId(savedReward) === window.mongoId(product)
  })

  const rewardVal = incentiveCostValues(product, country)
  const resultantCost = rewardVal.cost
    ? i18n.t(costLabel, { cost: rewardVal.transformCost })
    : ''

  const resultantIncentive = rewardVal.incentive
    ? i18n.t(incentiveLabel, { incentive: rewardVal.transformIncentive })
    : ''

  const totallReward =
    rewardVal.cost && rewardVal.incentive
      ? ` ${resultantIncentive} + ${resultantCost}`
      : ''

  // for dual currency
  const dollarCostLable = i18n.t('productCostLabelDollar')
  const dollarIncentiveLable = i18n.t('productIncentiveLabelDollar')

  const totallRewardInDollar =
    rewardVal.dollarCost && rewardVal.dollarIncentive
      ? `${dollarIncentiveLable} ${rewardVal.dollarIncentive} + ${dollarCostLable} ${rewardVal.dollarCost}`
      : ''
  const incentiveMessage = getIncentiveMessage(country, product, savedReward)

  if (dualCurrency) {
    const text = `${totallReward}${incentiveMessage}`
    return `${text} \n ${totallRewardInDollar} ${incentiveMessage}`
  }
  return `${totallReward}${incentiveMessage}`
}

export const usersnap = {
  add: user => {
    const key = process.env.REACT_APP_USERSNAP_KEY

    if (!window.Usersnap && key) {
      window.onUsersnapCXLoad = function (api) {
        api.init({
          user: {
            userId: user.id,
            email: user.emailAddress
          }
        })

        window.Usersnap = api
      }

      const script = document.createElement('script')
      script.classList.add('usersnap-script')
      script.defer = 1
      script.src = `https://widget.usersnap.com/global/load/${key}?onload=onUsersnapCXLoad`
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  },
  remove: () => {
    if (window.Usersnap) {
      window.Usersnap.destroy()
    }

    const script = document.querySelector('.usersnap-script')
    if (script) {
      script.remove()
    }
  }
}

const action = {
  calculateRewardText,
  calculateRewardToolTipText,
  incentiveCostValues
}

export default action
