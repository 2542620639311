import { GET, POST } from '@/utils/axios'

export const getSurvey = async criteria => await POST('/survey', criteria)

export const getActiveSurveys = async ({ userId, source }) => {
  const options = { useAuthorization: true }

  if (source) {
    return await GET(`/tasterSurveys/${userId}/${source}`, options)
  }

  return await GET(`/tasterSurveys/${userId}`, options)
}

export const surveyLogin = async formData =>
  await POST('/survey-login', formData)

export const surveyReject = async enrollmentId =>
  await GET(`/enrollment/${enrollmentId}/reject`)

export const getTasterPdfLink = async formData =>
  await POST('/tasterPdf', formData)

export const checkMetroCodeDate = async surveyId =>
  await GET(`/check-metro-code-date/${surveyId}`, { useAuthorization: true })
