import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import * as serviceWorker from '@/serviceWorker'

import App from '@/App'
import store from '@/store'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URI,
  ignoreErrors: [
    'SecurityError: The operation is insecure.', // https://bugzilla.mozilla.org/show_bug.cgi?id=303952
    'Illegal invocation',
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.'
  ],
  beforeSend (event, hint) {
    if (process.env.NODE_ENV !== 'production') return null
    if (hint.originalException === 'Timeout') return null
    return event
  }
})

window.mongoId = data => {
  return data && (data._id || data.id)
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
