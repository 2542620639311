import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { getTheme } from '@/apis'
import { DEFAULT_THEME } from '@/utils/constants'
import { GlobalStyle } from './styles'
import { setTheme } from '@/store/theme'

const Theme = ({ children }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { theme } = useSelector(state => state.theme)

  useEffect(() => {
    getTheme().then(({ data }) => {
      const newTheme = {
        ...DEFAULT_THEME,
        organization: data.organization,
        THEME_NAME: data.tasterSlug,
        ICON_URL: data.ICON_URL,
        FULL_LOGO_URL: data.FULL_LOGO_URL,
        TOKEN_HOURS: data.TOKEN_HOURS,
        ...data.themeSettings
      }

      if (data.APP_TITLE) {
        newTheme['APP_TITLE'] = data.APP_TITLE
      }

      if (data.NAME) {
        newTheme['NAME'] = data.NAME
      }

      if (data.ALLOW_SOCIAL_NETWORK !== undefined) {
        newTheme['ALLOW_SOCIAL_NETWORK'] = data.ALLOW_SOCIAL_NETWORK
      }

      if (data.ALLOW_TASTER !== undefined) {
        newTheme['ALLOW_TASTER'] = data.ALLOW_TASTER
      }

      if (data.ALLOW_SHARE !== undefined) {
        newTheme['ALLOW_SHARE'] = data.ALLOW_SHARE
      }

      setNewTheme(newTheme)
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1500)
  }, [pathname])

  const setNewTheme = newTheme => {
    document.title = newTheme['APP_TITLE']

    const faviconElement = document.querySelector('link[rel="icon"]')
    if (faviconElement) {
      faviconElement.setAttribute('href', newTheme['ICON_URL'])
    }

    dispatch(setTheme(newTheme))
  }

  return (
    <ThemeProvider theme={theme || DEFAULT_THEME}>
      {children}
      
      <GlobalStyle theme={theme} />
    </ThemeProvider>
  )
}

export default Theme
