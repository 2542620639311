export const COUNTRY_PHONE_CODES = [
  { name: 'Afghanistan', dial: '+93', code: 'AF', __typename: 'const_country' },
  {
    name: 'Åland Islands',
    dial: '+358',
    code: 'AX',
    __typename: 'const_country'
  },
  { name: 'Albania', dial: '+355', code: 'AL', __typename: 'const_country' },
  { name: 'Algeria', dial: '+213', code: 'DZ', __typename: 'const_country' },
  {
    name: 'American Samoa',
    dial: '+1684',
    code: 'AS',
    __typename: 'const_country'
  },
  { name: 'Andorra', dial: '+376', code: 'AD', __typename: 'const_country' },
  { name: 'Angola', dial: '+244', code: 'AO', __typename: 'const_country' },
  { name: 'Anguilla', dial: '+1264', code: 'AI', __typename: 'const_country' },
  { name: 'Antarctica', dial: '+672', code: 'AQ', __typename: 'const_country' },
  {
    name: 'Antigua and Barbuda',
    dial: '+1268',
    code: 'AG',
    __typename: 'const_country'
  },
  {
    name: 'Argentina',
    dial: '+54',
    code: 'AR',
    currency: 'AR',
    __typename: 'const_country'
  },
  { name: 'Armenia', dial: '+374', code: 'AM', __typename: 'const_country' },
  { name: 'Aruba', dial: '+297', code: 'AW', __typename: 'const_country' },
  {
    name: 'Australia',
    dial: '+61',
    code: 'AU',
    currency: 'AUD',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Austria', dial: '+43', code: 'AT', __typename: 'const_country' },
  { name: 'Azerbaijan', dial: '+994', code: 'AZ', __typename: 'const_country' },
  { name: 'Bahamas', dial: '+1242', code: 'BS', __typename: 'const_country' },
  { name: 'Bahrain', dial: '+973', code: 'BH', __typename: 'const_country' },
  {
    name: 'Bangladesh',
    dial: '+880',
    code: 'BD',
    currency: 'BDT',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Barbados', dial: '+1246', code: 'BB', __typename: 'const_country' },
  { name: 'Belarus', dial: '+375', code: 'BY', __typename: 'const_country' },
  { name: 'Belgium', dial: '+32', code: 'BE', __typename: 'const_country' },
  { name: 'Belize', dial: '+501', code: 'BZ', __typename: 'const_country' },
  { name: 'Benin', dial: '+229', code: 'BJ', __typename: 'const_country' },
  { name: 'Bermuda', dial: '+1441', code: 'BM', __typename: 'const_country' },
  { name: 'Bhutan', dial: '+975', code: 'BT', __typename: 'const_country' },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    dial: '+591',
    code: 'BO',
    __typename: 'const_country'
  },
  {
    name: 'Bosnia and Herzegovina',
    dial: '+387',
    code: 'BA',
    __typename: 'const_country'
  },
  { name: 'Botswana', dial: '+267', code: 'BW', __typename: 'const_country' },
  {
    name: 'Bouvet Island',
    dial: '+47',
    code: 'BV',
    __typename: 'const_country'
  },
  {
    name: 'Brazil',
    dial: '+55',
    code: 'BR',
    currency: 'BRL',
    // currency: 'USD',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'British Indian Ocean Territory',
    dial: '+246',
    code: 'IO',
    __typename: 'const_country'
  },
  {
    name: 'Brunei Darussalam',
    dial: '+673',
    code: 'BN',
    __typename: 'const_country'
  },
  {
    name: 'Bulgaria',
    dial: '+359',
    code: 'BG',
    currency: 'BGN',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Burkina Faso',
    dial: '+226',
    code: 'BF',
    __typename: 'const_country'
  },
  { name: 'Burundi', dial: '+257', code: 'BI', __typename: 'const_country' },
  { name: 'Cambodia', dial: '+855', code: 'KH', __typename: 'const_country' },
  { name: 'Cameroon', dial: '+237', code: 'CM', __typename: 'const_country' },
  {
    name: 'Canada',
    dial: '+1',
    code: 'CA',
    currency: 'CAD',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Cape Verde', dial: '+238', code: 'CV', __typename: 'const_country' },
  {
    name: 'Cayman Islands',
    dial: '+ 345',
    code: 'KY',
    __typename: 'const_country'
  },
  {
    name: 'Central African Republic',
    dial: '+236',
    code: 'CF',
    __typename: 'const_country'
  },
  { name: 'Chad', dial: '+235', code: 'TD', __typename: 'const_country' },
  {
    name: 'Chile',
    dial: '+56',
    code: 'CL',
    currency: 'CLP',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'China',
    dial: '+86',
    code: 'CN',
    currency: 'CNY',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Christmas Island',
    dial: '+61',
    code: 'CX',
    __typename: 'const_country'
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial: '+61',
    code: 'CC',
    __typename: 'const_country'
  },
  {
    name: 'Colombia',
    dial: '+57',
    code: 'CO',
    currency: 'COP',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Comoros', dial: '+269', code: 'KM', __typename: 'const_country' },
  { name: 'Congo', dial: '+242', code: 'CG', __typename: 'const_country' },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dial: '+243',
    code: 'CD',
    __typename: 'const_country'
  },
  {
    name: 'Cook Islands',
    dial: '+682',
    code: 'CK',
    __typename: 'const_country'
  },
  { name: 'Costa Rica', dial: '+506', code: 'CR', __typename: 'const_country' },
  {
    name: "Cote d'Ivoire",
    dial: '+225',
    code: 'CI',
    currency: 'XOF',
    languages:['en','fr'],
    __typename: 'const_country'
  },
  {
    name: 'Croatia',
    dial: '+385',
    code: 'HR',
    currency: 'HRK',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Cuba', dial: '+53', code: 'CU', __typename: 'const_country' },
  { name: 'Cyprus', dial: '+357', code: 'CY', __typename: 'const_country' },
  {
    name: 'Czech Republic',
    dial: '+420',
    code: 'CZ',
    __typename: 'const_country'
  },
  {
    name: 'Denmark',
    dial: '+45',
    code: 'DK',
    currency: 'DKK',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Djibouti', dial: '+253', code: 'DJ', __typename: 'const_country' },
  { name: 'Dominica', dial: '+1767', code: 'DM', __typename: 'const_country' },
  {
    name: 'Dominican Republic',
    dial: '+1849',
    code: 'DO',
    __typename: 'const_country'
  },
  { name: 'Ecuador', dial: '+593', code: 'EC', __typename: 'const_country' },
  {
    name: 'Egypt',
    dial: '+20',
    code: 'EG',
    currency: 'EGP',
    __typename: 'const_country'
  },
  {
    name: 'El Salvador',
    dial: '+503',
    code: 'SV',
    __typename: 'const_country'
  },
  {
    name: 'Equatorial Guinea',
    dial: '+240',
    code: 'GQ',
    __typename: 'const_country'
  },
  { name: 'Eritrea', dial: '+291', code: 'ER', __typename: 'const_country' },
  { name: 'Estonia', dial: '+372', code: 'EE', __typename: 'const_country' },
  { name: 'Ethiopia', currency: 'ETB', languages:['en','am'], dial: '+251', code: 'ET', __typename: 'const_country' },
  {
    name: 'Falkland Islands (Malvinas)',
    dial: '+500',
    code: 'FK',
    __typename: 'const_country'
  },
  {
    name: 'Faroe Islands',
    dial: '+298',
    code: 'FO',
    __typename: 'const_country'
  },
  { name: 'Fiji', dial: '+679', code: 'FJ', __typename: 'const_country' },
  { name: 'Finland', dial: '+358', code: 'FI', __typename: 'const_country' },
  {
    name: 'France',
    dial: '+33',
    code: 'FR',
    currency: 'EUR',
    __typename: 'const_country'
  },
  {
    name: 'French Guiana',
    dial: '+594',
    code: 'GF',
    __typename: 'const_country'
  },
  {
    name: 'French Polynesia',
    dial: '+689',
    code: 'PF',
    __typename: 'const_country'
  },
  {
    name: 'French Southern Territories',
    dial: '+262',
    code: 'TF',
    __typename: 'const_country'
  },
  { name: 'Gabon', dial: '+241', code: 'GA', __typename: 'const_country' },
  { name: 'Gambia', dial: '+220', code: 'GM', __typename: 'const_country' },
  {
    name: 'Georgia',
    dial: '+995',
    code: 'GE',
    currency: 'GEL',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Germany',
    dial: '+49',
    code: 'DE',
    currency: 'EUR',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Ghana',
    dial: '+233',
    code: 'GH',
    currency: 'GHS',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Gibraltar', dial: '+350', code: 'GI', __typename: 'const_country' },
  { name: 'Greece', dial: '+30', code: 'GR', __typename: 'const_country' },
  { name: 'Greenland', dial: '+299', code: 'GL', __typename: 'const_country' },
  { name: 'Grenada', dial: '+1473', code: 'GD', __typename: 'const_country' },
  { name: 'Guadeloupe', dial: '+590', code: 'GP', __typename: 'const_country' },
  { name: 'Guam', dial: '+1671', code: 'GU', __typename: 'const_country' },
  { name: 'Guatemala', dial: '+502', code: 'GT', __typename: 'const_country' },
  { name: 'Guernsey', dial: '+44', code: 'GG', __typename: 'const_country' },
  { name: 'Guinea', currency: 'GNF', languages:['en','fr'], dial: '+224', code: 'GN', __typename: 'const_country' },
  {
    name: 'Guinea-Bissau',
    dial: '+245',
    code: 'GW',
    __typename: 'const_country'
  },
  { name: 'Guyana', dial: '+592', code: 'GY', __typename: 'const_country' },
  { name: 'Haiti', dial: '+509', code: 'HT', __typename: 'const_country' },
  {
    name: 'Heard Island and Mcdonald Islands',
    dial: '+0',
    code: 'HM',
    __typename: 'const_country'
  },
  {
    name: 'Holy See (Vatican City State)',
    dial: '+379',
    code: 'VA',
    __typename: 'const_country'
  },
  { name: 'Honduras', dial: '+504', code: 'HN', __typename: 'const_country' },
  { name: 'Hong Kong', dial: '+852', code: 'HK', __typename: 'const_country' },
  {
    name: 'Hungary',
    dial: '+36',
    code: 'HU',
    currency: 'HUF',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Iceland', dial: '+354', code: 'IS', __typename: 'const_country' },
  {
    name: 'India',
    dial: '+91',
    code: 'IN',
    currency: 'INR',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Indonesia',
    dial: '+62',
    code: 'ID',
    currency: 'IDR',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dial: '+98',
    code: 'IR',
    __typename: 'const_country'
  },
  { name: 'Iraq', dial: '+964', code: 'IQ', __typename: 'const_country' },
  {
    name: 'Ireland',
    dial: '+353',
    code: 'IE',
    currency: 'EUR',
    __typename: 'const_country'
  },
  { name: 'Isle of Man', dial: '+44', code: 'IM', __typename: 'const_country' },
  {
    name: 'Israel',
    dial: '+972',
    code: 'IL',
    currency: 'ILS',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Italy',
    dial: '+39',
    code: 'IT',
    currency: 'EUR',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Jamaica', dial: '+1876', code: 'JM', __typename: 'const_country' },
  {
    name: 'Japan',
    dial: '+81',
    code: 'JP',
    currency: 'JPY',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Jersey', dial: '+44', code: 'JE', __typename: 'const_country' },
  { name: 'Jordan', dial: '+962', code: 'JO', __typename: 'const_country' },
  { name: 'Kazakhstan', dial: '+7', code: 'KZ', __typename: 'const_country' },
  {
    name: 'Kenya',
    dial: '+254',
    code: 'KE',
    currency: 'KES',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Kiribati', dial: '+686', code: 'KI', __typename: 'const_country' },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial: '+850',
    code: 'KP',
    __typename: 'const_country'
  },
  {
    name: 'Korea, Republic of South Korea',
    dial: '+82',
    code: 'KR',
    currency: 'KRW',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Kosovo', dial: '+383', code: 'XK', __typename: 'const_country' },
  { name: 'Kuwait', dial: '+965', code: 'KW', __typename: 'const_country' },
  { name: 'Kyrgyzstan', dial: '+996', code: 'KG', __typename: 'const_country' },
  { name: 'Laos', dial: '+856', code: 'LA', __typename: 'const_country' },
  { name: 'Latvia', dial: '+371', code: 'LV', __typename: 'const_country' },
  { name: 'Lebanon', dial: '+961', code: 'LB', __typename: 'const_country' },
  { name: 'Lesotho', dial: '+266', code: 'LS', __typename: 'const_country' },
  { name: 'Liberia', dial: '+231', code: 'LR', __typename: 'const_country' },
  {
    name: 'Libyan Arab Jamahiriya',
    dial: '+218',
    code: 'LY',
    __typename: 'const_country'
  },
  {
    name: 'Liechtenstein',
    dial: '+423',
    code: 'LI',
    __typename: 'const_country'
  },
  { name: 'Lithuania', dial: '+370', code: 'LT', __typename: 'const_country' },
  { name: 'Luxembourg', dial: '+352', code: 'LU', __typename: 'const_country' },
  { name: 'Macao', dial: '+853', code: 'MO', __typename: 'const_country' },
  { name: 'Macedonia', dial: '+389', code: 'MK', __typename: 'const_country' },
  { name: 'Madagascar', dial: '+261', code: 'MG', __typename: 'const_country' },
  { name: 'Malawi', dial: '+265', code: 'MW', __typename: 'const_country' },
  {
    name: 'Malaysia',
    dial: '+60',
    code: 'MY',
    currency: 'MYR',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Maldives', dial: '+960', code: 'MV', __typename: 'const_country' },
  { name: 'Mali', dial: '+223', code: 'ML', __typename: 'const_country' },
  { name: 'Malta', dial: '+356', code: 'MT', __typename: 'const_country' },
  {
    name: 'Marshall Islands',
    dial: '+692',
    code: 'MH',
    __typename: 'const_country'
  },
  { name: 'Martinique', dial: '+596', code: 'MQ', __typename: 'const_country' },
  { name: 'Mauritania', dial: '+222', code: 'MR', __typename: 'const_country' },
  { name: 'Mauritius', dial: '+230', code: 'MU', __typename: 'const_country' },
  { name: 'Mayotte', dial: '+262', code: 'YT', __typename: 'const_country' },
  {
    name: 'Mexico',
    dial: '+52',
    code: 'MX',
    currency: 'MXN',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dial: '+691',
    code: 'FM',
    __typename: 'const_country'
  },
  { name: 'Moldova', dial: '+373', code: 'MD', __typename: 'const_country' },
  { name: 'Monaco', dial: '+377', code: 'MC', __typename: 'const_country' },
  { name: 'Mongolia', dial: '+976', code: 'MN', __typename: 'const_country' },
  { name: 'Montenegro', dial: '+382', code: 'ME', __typename: 'const_country' },
  {
    name: 'Montserrat',
    dial: '+1664',
    code: 'MS',
    __typename: 'const_country'
  },
  {
    name: 'Morocco',
    dial: '+212',
    code: 'MA',
    currency: 'MAD',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Mozambique', dial: '+258', code: 'MZ', __typename: 'const_country' },
  { name: 'Myanmar', dial: '+95', code: 'MM', __typename: 'const_country' },
  { name: 'Namibia', dial: '+264', code: 'NA', __typename: 'const_country' },
  { name: 'Nauru', dial: '+674', code: 'NR', __typename: 'const_country' },
  { name: 'Nepal', dial: '+977', code: 'NP', __typename: 'const_country' },
  { name: 'Netherlands', dial: '+31', code: 'NL', __typename: 'const_country' },
  {
    name: 'Netherlands Antilles',
    dial: '+599',
    code: 'AN',
    __typename: 'const_country'
  },
  {
    name: 'New Caledonia',
    dial: '+687',
    code: 'NC',
    __typename: 'const_country'
  },
  {
    name: 'New Zealand',
    dial: '+64',
    code: 'NZ',
    currency: 'NZD',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Nicaragua', dial: '+505', code: 'NI', __typename: 'const_country' },
  { name: 'Niger', dial: '+227', code: 'NE', __typename: 'const_country' },
  {
    name: 'Nigeria',
    dial: '+234',
    code: 'NG',
    currency: 'NGN',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Niue', dial: '+683', code: 'NU', __typename: 'const_country' },
  {
    name: 'Norfolk Island',
    dial: '+672',
    code: 'NF',
    __typename: 'const_country'
  },
  {
    name: 'Northern Mariana Islands',
    dial: '+1670',
    code: 'MP',
    __typename: 'const_country'
  },
  {
    name: 'Norway',
    dial: '+47',
    code: 'NO',
    currency: 'NOK',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Oman', dial: '+968', code: 'OM', __typename: 'const_country' },
  {
    name: 'Pakistan',
    dial: '+92',
    code: 'PK',
    currency: 'PKR',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Palau', dial: '+680', code: 'PW', __typename: 'const_country' },
  {
    name: 'Palestinian Territory, Occupied',
    dial: '+970',
    code: 'PS',
    __typename: 'const_country'
  },
  { name: 'Panama', dial: '+507', code: 'PA', __typename: 'const_country' },
  {
    name: 'Papua New Guinea',
    dial: '+675',
    code: 'PG',
    __typename: 'const_country'
  },
  { name: 'Paraguay', dial: '+595', code: 'PY', __typename: 'const_country' },
  {
    name: 'Peru',
    dial: '+51',
    code: 'PE',
    currency: 'PEN',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Philippines',
    dial: '+63',
    code: 'PH',
    currency: 'PHP',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Pitcairn', dial: '+64', code: 'PN', __typename: 'const_country' },
  {
    name: 'Poland',
    dial: '+48',
    code: 'PL',
    currency: 'PLN',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Portugal', dial: '+351', code: 'PT', __typename: 'const_country' },
  {
    name: 'Puerto Rico',
    dial: '+1939',
    code: 'PR',
    __typename: 'const_country'
  },
  { name: 'Qatar', dial: '+974', code: 'QA', __typename: 'const_country' },
  {
    name: 'Romania',
    dial: '+40',
    code: 'RO',
    currency: 'RON',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Russia',
    dial: '+7',
    code: 'RU',
    currency: 'RUB',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Rwanda', dial: '+250', code: 'RW', __typename: 'const_country' },
  { name: 'Reunion', dial: '+262', code: 'RE', __typename: 'const_country' },
  {
    name: 'Saint Barthelemy',
    dial: '+590',
    code: 'BL',
    __typename: 'const_country'
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial: '+290',
    code: 'SH',
    __typename: 'const_country'
  },
  {
    name: 'Saint Kitts and Nevis',
    dial: '+1869',
    code: 'KN',
    __typename: 'const_country'
  },
  {
    name: 'Saint Lucia',
    dial: '+1758',
    code: 'LC',
    __typename: 'const_country'
  },
  {
    name: 'Saint Martin',
    dial: '+590',
    code: 'MF',
    __typename: 'const_country'
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial: '+508',
    code: 'PM',
    __typename: 'const_country'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial: '+1784',
    code: 'VC',
    __typename: 'const_country'
  },
  { name: 'Samoa', dial: '+685', code: 'WS', __typename: 'const_country' },
  { name: 'San Marino', dial: '+378', code: 'SM', __typename: 'const_country' },
  {
    name: 'Sao Tome and Principe',
    dial: '+239',
    code: 'ST',
    __typename: 'const_country'
  },
  {
    name: 'Saudi Arabia',
    dial: '+966',
    code: 'SA',
    __typename: 'const_country'
  },
  { name: 'Senegal', currency: 'XOF', languages:['en','fr'], dial: '+221', code: 'SN', __typename: 'const_country' },
  { name: 'Serbia', dial: '+381', code: 'RS', __typename: 'const_country' },
  { name: 'Seychelles', dial: '+248', code: 'SC', __typename: 'const_country' },
  {
    name: 'Sierra Leone',
    dial: '+232',
    code: 'SL',
    __typename: 'const_country'
  },
  {
    name: 'Singapore',
    dial: '+65',
    code: 'SG',
    currency: 'SGD',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Slovakia', dial: '+421', code: 'SK', __typename: 'const_country' },
  { name: 'Slovenia', dial: '+386', code: 'SI', __typename: 'const_country' },
  {
    name: 'Solomon Islands',
    dial: '+677',
    code: 'SB',
    __typename: 'const_country'
  },
  { name: 'Somalia', dial: '+252', code: 'SO', __typename: 'const_country' },
  {
    name: 'South Africa',
    dial: '+27',
    code: 'ZA',
    currency: 'ZAR',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'South Sudan',
    dial: '+211',
    code: 'SS',
    __typename: 'const_country'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial: '+500',
    code: 'GS',
    __typename: 'const_country'
  },
  { name: 'Spain', dial: '+34', currency: 'EUR', code: 'ES', __typename: 'const_country', languages:['en','es'] },
  {
    name: 'Sri Lanka',
    dial: '+94',
    code: 'LK',
    currency: 'LKR',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Sudan', dial: '+249', code: 'SD', __typename: 'const_country' },
  { name: 'Suriname', dial: '+597', code: 'SR', __typename: 'const_country' },
  {
    name: 'Svalbard and Jan Mayen',
    dial: '+47',
    code: 'SJ',
    __typename: 'const_country'
  },
  { name: 'Swaziland', dial: '+268', code: 'SZ', __typename: 'const_country' },
  {
    name: 'Sweden',
    dial: '+46',
    code: 'SE',
    currency: 'SEK',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Switzerland',
    dial: '+41',
    code: 'CH',
    currency: 'CHF',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Syrian Arab Republic',
    dial: '+963',
    code: 'SY',
    __typename: 'const_country'
  },
  { name: 'Taiwan', dial: '+886', code: 'TW', __typename: 'const_country' },
  { name: 'Tajikistan', dial: '+992', code: 'TJ', __typename: 'const_country' },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dial: '+255',
    code: 'TZ',
    __typename: 'const_country'
  },
  {
    name: 'Thailand',
    dial: '+66',
    code: 'TH',
    currency: 'THB',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Timor-Leste',
    dial: '+670',
    code: 'TL',
    __typename: 'const_country'
  },
  { name: 'Togo', dial: '+228', code: 'TG', __typename: 'const_country' },
  { name: 'Tokelau', dial: '+690', code: 'TK', __typename: 'const_country' },
  { name: 'Tonga', dial: '+676', code: 'TO', __typename: 'const_country' },
  {
    name: 'Trinidad and Tobago',
    dial: '+1868',
    code: 'TT',
    __typename: 'const_country'
  },
  { name: 'Tunisia', dial: '+216', code: 'TN', __typename: 'const_country' },
  {
    name: 'Turkey',
    dial: '+90',
    code: 'TR',
    currency: 'TRY',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Turkmenistan',
    dial: '+993',
    code: 'TM',
    __typename: 'const_country'
  },
  {
    name: 'Turks and Caicos Islands',
    dial: '+1649',
    code: 'TC',
    __typename: 'const_country'
  },
  { name: 'Tuvalu', dial: '+688', code: 'TV', __typename: 'const_country' },
  { name: 'Uganda', dial: '+256', code: 'UG', __typename: 'const_country' },
  {
    name: 'Ukraine',
    dial: '+380',
    code: 'UA',
    currency: 'UAH',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'United Arab Emirates',
    dial: '+971',
    code: 'AE',
    currency: 'AED',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'United Kingdom',
    dial: '+44',
    code: 'GB',
    currency: 'GBP',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'United States of America',
    dial: '+1',
    code: 'US',
    currency: 'USD',
    referralAmount: 0,
    __typename: 'const_country'
  },
  { name: 'Uruguay', dial: '+598', code: 'UY', __typename: 'const_country' },
  { name: 'Uzbekistan', dial: '+998', code: 'UZ', __typename: 'const_country' },
  { name: 'Vanuatu', dial: '+678', code: 'VU', __typename: 'const_country' },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dial: '+58',
    code: 'VE',
    __typename: 'const_country'
  },
  {
    name: 'Vietnam',
    dial: '+84',
    code: 'VN',
    currency: 'VND',
    referralAmount: 0,
    __typename: 'const_country'
  },
  {
    name: 'Virgin Islands, British',
    dial: '+1284',
    code: 'VG',
    __typename: 'const_country'
  },
  {
    name: 'Virgin Islands, U.S.',
    dial: '+1340',
    code: 'VI',
    __typename: 'const_country'
  },
  {
    name: 'Wallis and Futuna',
    dial: '+681',
    code: 'WF',
    __typename: 'const_country'
  },
  { name: 'Yemen', dial: '+967', code: 'YE', __typename: 'const_country' },
  { name: 'Zambia', dial: '+260', code: 'ZM', __typename: 'const_country' },
  { name: 'Zimbabwe', dial: '+263', code: 'ZW', __typename: 'const_country' }
]
