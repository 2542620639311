import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, useLocation } from 'react-router-dom'
import useLanguage from '@/hooks/useLanguage'

const RoutePublic = ({ children, ...props }) => {
  const location = useLocation()
  const { authenticatedUser } = useSelector(state => state.authentication)
  const [_, setLanguage] = useLanguage()

  useEffect(() => {
    if (location && location.search) {
      const urlSearchParams = new URLSearchParams(location.search)

      if (urlSearchParams.has('lang')) {
        const lang = urlSearchParams.get('lang')
        setLanguage(lang)
      }
    }
  }, [location])

  return (
    <Route
      {...props}
      render={({ location }) =>
        authenticatedUser ? (
          <Redirect
            to={{
              ...location,
              pathname: '/dashboard',
              state: { from: location }
            }}
          />
        ) : (
          children
        )
      }
    />
  )
}

export default RoutePublic
